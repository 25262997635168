import axios from "./axios.js";
import {
  newUrl
} from "./baseUrl.js";

function newApi(data) {
  return axios.request({
    ...data,
    baseURL: newUrl
  })
}
// 列表
export function getPredictionList(params) {
  return newApi({
    method: 'GET',
    url: '/product/prediction/list',
    params
  })
}
export function addPrediction(data) {
  return newApi({
    method: 'POST',
    url: '/product/prediction/add',
    data
  })
}
export function editPrediction(data) {
  return newApi({
    method: 'PUT',
    url: '/product/prediction/edit',
    data
  })
}
export function chgSts(data) {
  return newApi({
    method: 'POST',
    url: '/product/prediction/chgSts',
    data
  })
}
// 取消
export function cancel(data) {
  return newApi({
    method: 'POST',
    url: '/product/prediction/cancel',
    data
  })
}
// 确认取消
export function cancelConfirm(data) {
  return newApi({
    method: 'POST',
    url: '/product/prediction/cancelConfirm',
    data
  })
}
// 客户用户列表
export function getCustomerListAll(params) {
  return newApi({
    method: 'GET',
    url: '/cust/customer/listAll',
    params
  })
}
export function getCustomerInfoPage(query) {
    return newApi({
        url: '/customerInfo/page',
        method: 'get',
        params: query
    })
}

// 获取企业微信授权地址
export function getAuthorizeUrl(params) {
  return newApi({
    method: 'GET',
    url: '/system/corpWx/getAuthorizeUrl',
    params
  })
}
// 企业微信绑定用户
export function corpWxUserBind(data) {
  return newApi({
    method: 'POST',
    url: '/corpWxUserBind',
    data
  })
}
// 企业微信登录验证
export function corpWxUserLogin(data) {
  return newApi({
    method: 'POST',
    url: '/corpWxUserLogin',
    data
  })
}
// 确认预报
export function predictionConfirm(data) {
  return newApi({
    method: 'POST',
    url: '/product/prediction/confirm',
    data
  })
}
// 获取权限
export function getMenuList(params) {
  return newApi({
    method: 'GET',
    url: '/getInfo',
    params
  })
}

// 渠道列表
export function getChannelList(query) {
  return newApi({
      url: '/product/salescycle/listall',
      method: 'get',
      params: query
  })
}

// 查询跟进记录列表
export function listCustrecord(params) {
  return newApi({
    url: '/cust/followrecord/custlist',
    method: 'get',
    params
  })
}

// 新增记录
export function addFollowrecord(data) {
  return newApi({
    url: '/cust/followrecord/add',
    method: 'post',
    data
  })
}

// 修改记录
export function updateFollowrecord(data) {
  return newApi({
    url: '/cust/followrecord/edit',
    method: 'put',
    data
  })
}

// 设置有效
export function listpassid(data) {
  return newApi({
    method: 'POST',
    url: '/cust/followrecord/passid',
    data: data
  })
}

// 设置无效
export function listrejectid(data) {
  return newApi({
    method: 'POST',
    url: '/cust/followrecord/rejectid',
    data
  })
}

// 查询详细
export function getFollowrecord(recordId) {
  return newApi({
    url: '/cust/followrecord/' + recordId,
    method: 'get'
  })
}

export function selectFBA(query) {
    return newApi({
        url: '/wms/warehouse/selectFBA',
        method: 'get',
        params: query
    })
}

// 批量设置有效
export function listpass(data) {
    return newApi({
        method: 'POST',
        url: '/cust/followrecord/pass',
        data: data
    })
}

// 批量设置无效
export function listreject(data) {
    return newApi({
        method: 'POST',
        url: '/cust/followrecord/reject',
        data: data
    })
}