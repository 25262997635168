<template>
  <div class="order-page">
    <van-nav-bar class="title"
                 @click-left="handleBack"
                 left-arrow>
      <div slot="title">
        <span>保存预报</span>
      </div>
    </van-nav-bar>
    <div class="content">
      <v-form ref="form"
              v-model="valid"
              lazy-validation>
        <!-- <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            渠道名称
          </div>
          <v-select v-model="form.prdName"
                    :rules="[value => !!value || '请选择渠道名称']"
                    :items="channelList"
                    hide-details="auto"
                    placeholder="请选择渠道名称"></v-select>
        </div> -->

        <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            跟进方式
          </div>
          <v-radio-group v-model="form.communicateType"
                         :rules="[value => !!value || '请选择跟进方式']"
                         dense
                         hide-details="auto"
                         :column="false">
            <v-radio label="电话"
                     value="0"></v-radio>
            <v-radio label="会面"
                     value="1"></v-radio>
                     <v-radio label="网络"
                     value="2"></v-radio>
          </v-radio-group>
        </div>

        <div class="input-item"
             @click="handleSelectCustomer">
          <div class="label">
            <span class="required">*</span>
            客户名称
          </div>
          <v-text-field class="customer-input"
                        :value="form.customerName"
                        readonly
                        :rules="[value => !!value || '请选择客户']"
                        dense
                        hide-details="auto"
                        placeholder="请选择客户"></v-text-field>
          <van-icon name="arrow"
                    size="18"
                    class="right-arrow"
                    color="#9e9e9e" />
        </div>

        <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            联系人
          </div>
          <v-text-field :rules="[value => !!value || '请输入联系人']"
                        v-model.number="form.contactName"
                        hide-details="auto"
                        dense
                        placeholder="请输入体积">
          </v-text-field>
        </div>

        <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            跟进日期
          </div>
          <v-menu v-model="dateMenuShow"
                  content-class="dateMenuCenter"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  fixed>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="form.followTime"
                            :rules="[value => !!value || '请选择跟进日期']"
                            hide-details="auto"
                            placeholder="请选择跟进日期"
                            readonly
                            v-bind="attrs"
                            v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="form.followTime"
                           :first-day-of-week="1"
                           :day-format="dayFormat"
                           no-title
                           scrollable
                           @input="dateMenuShow = false">
            </v-date-picker>
          </v-menu>
        </div>

        <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            跟进目的
          </div>
          <v-radio-group v-model="form.followObjective"
                         :rules="[value => !!value || '请选择跟进方式']"
                         dense
                         hide-details="auto"
                         :column="false">
            <v-radio label="客情维护"
                     value="0"></v-radio>
            <v-radio label="需求挖掘"
                     value="1"></v-radio>
                     <v-radio label="需求确认"
                     value="2"></v-radio>
                     <v-radio label="异议处理"
                     value="3"></v-radio>
                     <v-radio label="合同签署"
                     value="4"></v-radio>
                     <v-radio label="价值塑造"
                     value="5"></v-radio>
          </v-radio-group>
        </div>

        <div class="input-item">
          <div class="label">
            <span class="required">*</span>
            内容
          </div>
          <v-text-field :rules="[value => !!value || '请输入跟进内容']"
                        v-model.number="form.content"
                        hide-details="auto"
                        dense
                        placeholder="请输入体积">
          </v-text-field>
        </div>

      </v-form>
    </div>
    <div class="content-bottom">
      <v-btn class="submit"
             :loading="loading"
             :disabled="loading"
             @click="handleSubmit">
        提交
      </v-btn>
    </div>
    <v-dialog v-model="selectDialog"
              content-class="select-dialog"
              max-width="600"
              max-height="800">
      <customer-list v-if="selectDialog"
                     @change="selectDialog = false"
                     :userList="userList" />
    </v-dialog>
    <v-dialog v-model="selectChannelDialog"
              content-class="select-dialog"
              max-width="600"
              max-height="800">
      <channel-list v-if="selectChannelDialog"
                     @change="selectChannelDialog = false"
                     :userList="channelList" />
    </v-dialog>
  </div>
</template>
<script>
import {
  getChannelList,
  getWarehouseList
} from "@/api/api.js";
import {
  addPrediction,
  getCustomerListAll,
  editPrediction,
} from "@/api/newApi.js";
import {
  addFollowrecord
} from "@/api/newApi.js";
import CustomerList from "./components/customer-list.vue";
import ChannelList from "./components/channel-list.vue";
import {getCustomerInfoPage} from "../../api/customer";
export default {
  name: "save",
  components: {
    CustomerList,
    ChannelList,
  },
  data: () => ({
    id: "",
    valid: true,
    loading: false,
    form: {
      "custName": "", // 客户名称
      "deliverywareCode": "",
      "deliverywareDate": "",
      "ifCustoms": "", // 是否报关
      "note": "", // 是否接受升舱
      "prdId": "",
      "prdName": "",
      "remark": "", // 备注
      "volume": "", // 体积
      "warehouseCode": "", // 仓库代码
      "zipcode": "",// 邮编
    },
    channelList: [],// 渠道列表
    warehouseList: [],
    editInfo: {},
    dateMenuShow: false,
    selectDialog: false,
    userList: [],
    selectChannelDialog:false,
  }),
  computed: {
    selectCustomerForSave () {
      return this.$store.getters.selectCustomerForSave
    },
    setSelectChannelForSave () {
      return this.$store.getters.selectChannelForSave
    }
  },
  watch: {
    selectCustomerForSave: {
      handler (val) {
        if (val) {
          this.$store.dispatch("setSelectCustomerForSave", "")
          this.form.custName = val;
        }
      },
      immediate: true
    },
    setSelectChannelForSave: {
      handler (val) {
        if (val) {
          this.$store.dispatch("setSelectChannelForSave", "")
          this.form.prdName = val;
        }
      },
      immediate: true
    }
  },
  created () {
    let form = sessionStorage.getItem("saveForm");
    form = JSON.parse(form);
    if (form) {
      delete form.custName;
      this.form = {
        ...this.form,
        ...form
      }
      sessionStorage.removeItem("saveForm");
    }
  },
  mounted () {
    this.id = this.$route.query.id;
    this.getChannelList();
    this.getWarehouseList();
    this.getCustomerListAll()
    if (this.id) {
      let str = localStorage.getItem("forecastItem");
      if (str) {
        try {
          let form = JSON.parse(str);
          this.editInfo = form;
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(this.form, key)) {
              this.form[key] = form[key];
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

  },
  methods: {
    handleBack () {
      this.$router.back();
    },
    handleSubmit () {
      if (!this.$refs.form.validate()) return;
      let form = { ...this.form };
      form.status = "0";
      let fn = addFollowrecord;
      if (this.id) {
        fn = editPrediction;
        form.id = this.editInfo.id;
      }
      this.loading = true;
      fn(form).then((res) => {
        this.$toast("保存成功")
        this.loading = false;
        setTimeout(() => {
          this.$router.replace("/")
        }, 1500);
      }).catch((err) => {
        this.loading = false;
        console.log(err);
        this.$toast(err?.msg || "保存失败!请重试")
      });
    },
    dayFormat (str) {
      str = str.slice(-2);
      return Number(str)
    },
    // 触发选择客户
    handleSelectCustomer () {
      if (window.screen.width > 520) {
        this.selectDialog = true;
      } else {
        this.$router.push("/customer")
      }
    },
    handleSelectChannel(){
      if (window.screen.width > 520) {
        this.selectChannelDialog = true;
      } else {
        this.$router.push("/channel")
      }
    },
    // 获取仓库
    getWarehouseList () {
      this.loading = true;
      let param = {
      }
      getWarehouseList(param).then((res) => {
        let list = res.result || [];
        list = list.map(item => {
          return {
            text: item.name,
            value: item.name,
          }
        })
        this.warehouseList = list;
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.loading = false;
      });
    },
    // 获取用户
    getCustomerListAll () {
      let param = {
      }
        getCustomerInfoPage(param).then((res) => {
        let list = res.data.records || [];
        this.userList = list;
      }).catch((err) => {
        console.log(err);
      });
    },
    // 获取渠道
    getChannelList () {
      let param = {
      }
      getChannelList(param).then((res) => {
        let list = res.result || [];
        // list = list.map(item => {
        //   return {
        //     text: item.channelName,
        //     value: item.channelName,
        //   }
        // })
        this.channelList = list;
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        let msg = err.msg || "网络异常"
        this.$toast(msg)
        this.loading = false;
      });
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.path == '/customer') {
      sessionStorage.setItem("saveForm", JSON.stringify(this.form))
      to.meta.keepAlive = true;
    }
    next();
  },
}
</script>
<style lang="scss" scoped>
.order-page {
  position: relative;
  // width:100%;
  height: 100vh;
  background-color: #f5f6fa;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 2.8rem;
  font-size: 0.64rem;
  box-sizing: border-box;
  z-index: 1;

  > div:not(.van-popup, .van-nav-bar) {
    position: relative;
    z-index: 1;
  }
}

:deep(.v-input) {
  margin: 0;
  padding: 0;

  input {
    padding: 0.08rem 0 !important;
    text-align: right;
  }

  .v-input__slot {
    &::after,
    &::before {
      display: none;
    }
  }
}

.v-radio {
  margin-left: 0.8rem;
}

.order-page :deep(.v-select__selections) {
  justify-content: flex-end;

  input:last-child {
    flex-grow: 0;
  }

  input:first-child {
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
}

:deep(.v-list-item__title) {
  font-size: 0.56rem;
}

:deep(.v-messages__message) {
  text-align: right;
}

.v-picker {
  font-size: 0.56rem;
}

// 标题
.title {
  text-align: left;

  :deep(.van-nav-bar__title) {
    margin: 0;
    margin-left: 3rem;
    font-weight: normal;
    font-size: 16px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  :deep(.van-nav-bar__right:active) {
    opacity: 1;
  }

  .btn-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding: 0.08rem 0.4rem;
      color: dodgerblue;
      transition: all 0.3;

      &:active {
        opacity: 0.7;
      }
    }
  }
}

.content,
.content-bottom {
  width: 13.72rem;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 0.48rem;
}

.content-bottom {
  background: none;
}

.required {
  font-size: 12px;
  color: red;
}

.input-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
  margin: 0 0.4rem 0 0.56rem;
  font-size: 0.56rem;
  color: #333333;
  line-height: 0.56rem;
  border-bottom: 1px solid #E8E8E8;

  .label {
    flex-shrink: 0;
    margin-right: 0.32rem;
  }
}

.v-input.customer-input {
  :deep(input) {
    color: #222;
  }

  :deep(.v-messages__message) {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
}

.order-page .v-btn.submit {
  display: block;
  margin: 1.2rem auto 0;
  width: 12rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: #3890FF;
  color: #fff;
  font-size: 0.64rem;
  border-radius: 4rem;
}

@media screen and (min-width: 500px) {
  .content,
  .content-bottom {
    margin: 0;
    margin-left: 0.64rem;
    width: 90%;
    max-width: 600px;
  }

  .content-bottom {
    position: fixed !important;
    bottom: 50px;
  }

  .input-item {
    padding: 20px 10px;
  }

  .order-page .v-btn.submit {
  }
}

:deep(.select-dialog) {
  height: 600px !important;
  max-width: 500px !important;

  .customer-list {
    max-height: 600px !important;
  }

  .van-list {
    max-height: 545px !important;
  }
}
</style>
<style>
.dateMenuCenter.v-menu__content {
  left: 50% !important;
  transform: translateX(-50%);
}
</style>