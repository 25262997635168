import { render, staticRenderFns } from "./followrecord.vue?vue&type=template&id=32e8c878&scoped=true&"
import script from "./followrecord.vue?vue&type=script&lang=js&"
export * from "./followrecord.vue?vue&type=script&lang=js&"
import style0 from "./followrecord.vue?vue&type=style&index=0&id=32e8c878&prod&lang=scss&scoped=true&"
import style1 from "./followrecord.vue?vue&type=style&index=1&id=32e8c878&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e8c878",
  null
  
)

export default component.exports